.slbOverlay, .slbWrapOuter, .slbWrap {
  position: fixed;
  inset: 0;
}

.slbOverlay {
  z-index: 2000;
  opacity: .7;
  background-color: #000;
  animation: .5s slbOverlay;
  overflow: hidden;
}

.slbWrapOuter {
  z-index: 2010;
  overflow: hidden auto;
}

.slbWrap {
  text-align: center;
  position: absolute;
}

.slbWrap:before {
  content: "";
  vertical-align: middle;
  height: 100%;
  display: inline-block;
}

.slbContentOuter {
  vertical-align: middle;
  box-sizing: border-box;
  z-index: 2020;
  text-align: left;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 1em;
  display: inline-block;
  position: relative;
}

.slbContentEl .slbContentOuter {
  padding: 5em 1em;
}

.slbContent {
  position: relative;
}

.slbContentEl .slbContent {
  background-color: #fff;
  animation: .3s slbEnter;
  box-shadow: 0 .2em 1em #0006;
}

.slbImageWrap {
  animation: .3s slbEnter;
  position: relative;
}

.slbImageWrap:after {
  content: "";
  z-index: -1;
  background-color: #fff;
  display: block;
  position: absolute;
  inset: 5em 0;
  box-shadow: 0 .2em 1em #0009;
}

.slbDirectionNext .slbImageWrap {
  animation: .4s slbEnterNext;
}

.slbDirectionPrev .slbImageWrap {
  animation: .4s slbEnterPrev;
}

.slbImage {
  box-sizing: border-box;
  width: auto;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 5em 0;
  line-height: 0;
  display: block;
}

.slbCaption {
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  color: #ffffffb3;
  text-align: center;
  max-width: 100%;
  padding: .71429em 0;
  font-size: 1.4em;
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.slbCloseBtn, .slbArrow {
  cursor: pointer;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
}

.slbCloseBtn::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.slbArrow::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.slbCloseBtn:hover, .slbArrow:hover {
  opacity: .5;
}

.slbCloseBtn:active, .slbArrow:active {
  opacity: .8;
}

.slbCloseBtn {
  color: #ffffffb3;
  text-align: center;
  width: 1.66667em;
  height: 1.66667em;
  font-size: 3em;
  line-height: 1.66667em;
  animation: .3s slbEnter;
  position: absolute;
  top: 0;
  right: -.33333em;
}

.slbLoading .slbCloseBtn {
  display: none;
}

.slbLoadingText {
  color: #ffffffe6;
  font-size: 1.4em;
}

.slbArrows {
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
}

.slbLoading .slbArrows {
  display: none;
}

.slbArrow {
  opacity: .7;
  text-indent: -999em;
  width: 5em;
  height: 10em;
  margin-top: -5em;
  position: absolute;
  top: 50%;
  overflow: hidden;
}

.slbArrow:before {
  content: "";
  border: .8em solid #0000;
  margin: -.8em 0 0 -.8em;
  position: absolute;
  top: 50%;
  left: 50%;
}

.slbArrow.next {
  right: 0;
}

.slbArrow.next:before {
  border-left-color: #fff;
}

.slbArrow.prev {
  left: 0;
}

.slbArrow.prev:before {
  border-right-color: #fff;
}

.slbIframeCont {
  width: 80em;
  height: 0;
  margin: 5em 0;
  padding-top: 56.25%;
  overflow: hidden;
}

.slbIframe {
  background: #000;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 .2em 1em #0009;
}

@keyframes slbOverlay {
  from {
    opacity: 0;
  }

  to {
    opacity: .7;
  }
}

@-webkit-keyframes slbEnter {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -1em, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
  }
}

@-moz-keyframes slbEnter {
  from {
    opacity: 0;
    -moz-transform: translate3d(0, -1em, 0);
  }

  to {
    opacity: 1;
    -moz-transform: translate3d(0, 0, 0);
  }
}

@keyframes slbEnter {
  from {
    opacity: 0;
    transform: translate3d(0, -1em, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes slbEnterNext {
  from {
    opacity: 0;
    -webkit-transform: translate3d(4em, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
  }
}

@-moz-keyframes slbEnterNext {
  from {
    opacity: 0;
    -moz-transform: translate3d(4em, 0, 0);
  }

  to {
    opacity: 1;
    -moz-transform: translate3d(0, 0, 0);
  }
}

@keyframes slbEnterNext {
  from {
    opacity: 0;
    transform: translate3d(4em, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes slbEnterPrev {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-4em, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
  }
}

@-moz-keyframes slbEnterPrev {
  from {
    opacity: 0;
    -moz-transform: translate3d(-4em, 0, 0);
  }

  to {
    opacity: 1;
    -moz-transform: translate3d(0, 0, 0);
  }
}

@keyframes slbEnterPrev {
  from {
    opacity: 0;
    transform: translate3d(-4em, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
/*# sourceMappingURL=index.4f327621.css.map */
